/** *** USER SEARCH ***** */
const initialState = {
  overlayOnScreen: '',
  overlayOnScreenData: '',

  overlayOnScreenResponsive: '',
  overlayOnScreenResponsiveData: '',

  overlayOnScreenResponsiveLoading: '',
  overlayOnScreenLoadingData: '',
};

const CommonActionsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'TOGGLE_OVERLAY_ON_SCREEN':
    {
      const clone = Object.assign({}, state);
      if (action.request === 'open') {
        clone.overlayOnScreen = action.id;
        clone.overlayOnScreenData = action.data;
      }
      if (action.request === 'close') {
        clone.overlayOnScreen = '';
        clone.overlayOnScreenData = '';
      }
      return clone;
    }

    case 'TOGGLE_OVERLAY_ON_SCREEN_RESPONSIVE':
    {
      const clone = Object.assign({}, state);
      if (action.request === 'open') {
        clone.overlayOnScreenResponsive = action.id;
        clone.overlayOnScreenResponsiveData = action.data;
      }
      if (action.request === 'close') {
        clone.overlayOnScreenResponsive = '';
        clone.overlayOnScreenResponsiveData = '';
      }
      return clone;
    }
    
    case 'TOGGLE_OVERLAY_ON_SCREEN_RESPONSIVE_LOADING':
    {
      const clone = Object.assign({}, state);
      if (action.request === 'open') {
        clone.overlayOnScreenResponsiveLoading = action.id;
        clone.overlayOnScreenLoadingData = action.data;
      }
      if (action.request === 'close') {
        clone.overlayOnScreenResponsiveLoading = '';
        clone.overlayOnScreenLoadingData = '';
      }
      return clone;
    }
    
    case 'UPDATE_OVERLAY_ON_SCREEN_RESPONSIVE_LOADING_DATA':
    {
      const clone = Object.assign({}, state);
      clone.overlayOnScreenLoadingData = action.data;
      return clone;
    }

    default:
      return state;
  }
};

export default CommonActionsReducer;
