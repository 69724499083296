import { combineReducers } from 'redux';

import Core from './Core';
import UI from './UI';
import Auth from './Auth';
import User from './User';
import ApiActions from './ApiActions';

import Sidebar from './Sidebar';
import CommonActions from './CommonActions';

/* OLD
const myStuff = combineReducers({
  UI,
  Auth,
  User,
  ApiActions,
  Sidebar,
  CommonActions,
});

export default myStuff;
*/


/*** NEW ****/
const appReducer = combineReducers({
  Core,
  UI,
  Auth,
  User,
  ApiActions,
  Sidebar,
  CommonActions,
})


/**********************************************************
 * rootReducer. This is where we can reset/clear state.
 * Otherwise, we return the appReducer which creates all
 * the state for the components requried.
***********************************************************/

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_STATE':
    {
      state = undefined
    }
    default:
      return appReducer(state, action);
  }
}
export default rootReducer;





/*
Set everything to undefined on logout - maybe not everything - ui still needs to work
this.setState(
  Object.keys(this.state).reduce((a, c) => {
    a[c] = undefined;
    return a;
  }, {})
)
*/