/** *** API ACTIONS (Monitor and report on API activity) ***** */
const initialState = {
  StatPeriodStart: null,    //Date/time for this stat period
  ApiSuccessCount: 0,
  ApiFailureCount: 0,
  ApiInProgress: 0,
  ApiErrors: {
    E400: 0,    //Bad Reqeust
    E401: 0,    //Unauthorized
    E403: 0,    //Forbidden
    E404: 0,    //Not Found
    E405: 0,    //Method Not Allowed
    E408: 0,    //Request Timeout
    E429: 0,    //Too Many Requests
    E500: 0,    //Internal Server Error
    E502: 0,    //Bad Gateway
    E503: 0,    //Service Unavailable
    E504: 0,    //Gateway Timeout
  }

};

const ApiActionsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'UPDATE_API_STATUS_CODES':
    {
      const clone = Object.assign({}, state);
      console.log(action);
      switch (action) {
        case '400': { clone.ApiErrors.E400 += 1; }
        case '401': { clone.ApiErrors.E401 += 1; }
        case '403': { clone.ApiErrors.E403 += 1; }
        case '404': { clone.ApiErrors.E404 += 1; }
        case '405': { clone.ApiErrors.E405 += 1; }
        case '408': { clone.ApiErrors.E408 += 1; }
        case '429': { clone.ApiErrors.E429 += 1; }

        case '500': { clone.ApiErrors.E500 += 1; }
        case '502': { clone.ApiErrors.E502 += 1; }
        case '503': { clone.ApiErrors.E503 += 1; }
        case '504': { clone.ApiErrors.E504 += 1; }
        default: {
          console.log("other error");
        }
      }


      
      return clone;
    }

    case 'UPDATE_API_SUCCESS_COUNT':
    {
      const clone = Object.assign({}, state);
      clone.ApiSuccessCount += 1;
      clone.ApiInProgress -= 1;
      return clone;
    }

    case 'UPDATE_API_FAILURE_COUNT':
    {
      const clone = Object.assign({}, state);
      clone.ApiFailureCount += 1;
      clone.ApiInProgress -= 1;
      return clone;
    }
    
    case 'UPDATE_API_INPROGRESS_COUNT':
    {
      const clone = Object.assign({}, state);
      clone.ApiInProgress += 1;
      return clone;
    }
 
    default:
      return state;
  }
};

export default ApiActionsReducer;
