import _ from 'lodash';

/** *** CORE STATE ***** */
const initialState = {
  lastStateReset: '',

  test1: 'aaa',
  test2: [],
  test3: {
    one:'1',
    two:'2',
  },
  test4: [
    {item1: 'a', desc1: 'b'},
    {item2: 'c', desc2: 'd'},
  ],
  test5: {
    nested1: {
      arr1: [1, 2, 3, 4],
      arr2: ["a", "b", "c", "d"],
      arr3: [
        {item1: 'a', desc1: 'b'},
        {item2: 'c', desc2: 'd'},
      ],
    },
    nested2: {
      arr1: [5, 6, 7, 8],
      arr2: ["e", "f", "g", "h"],
      arr3: [
        {name: 'a', prop1: '', prop2: '', item1: 'e', desc1: 'f'},
        {name: 'b', prop1: '', prop2: '', item2: 'g', desc2: 'h'},
      ],
    }
  }
};

const CoreReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CHANGE_STATE':
    {
      const clone = Object.assign({}, state);
      console.log('CORE - CHANGE_STATE');
      const dateNow = Date(Date.now()); 
      clone.lastStateReset = dateNow.toString();
      return clone;
    }

    case 'EXAMPLE_UPDATE':    //DETAILS
    {
      /* Required inputs: dotPath, updateValue
      */
      //(1) - Clone State
      //(2) - Create an Update Clone of State for changes
      //(3) - Set the new values on the update Clone. When updating, all other fields in the Update Clone will be removed resulting with just the single update field.
      //(4) - With the updated object, merge this back into clone. (clone, cloneItemUpdate) - This will overwrite clone with any changes in cloneItemUpdate.
      const clone = Object.assign({}, state);
      let cloneItemUpdate = Object.assign({}, clone);
      _.set(cloneItemUpdate, 'test5.nested1.arr3[0]', { item1: 'aaaaa', desc1: 'bbbbb' });    //PATH & UPDATE is defined in ACTIONS (reducer just implements)
      _.merge(clone, cloneItemUpdate);
      return clone;
    }

    case 'TEST_UPDATE':
    {
      const clone = Object.assign({}, state);
      console.log('CORE - TEST_UPDATE');
      console.log(clone);
      let cloneItemUpdate = Object.assign({}, clone);
      _.set(cloneItemUpdate, 'test5.nested1.arr3[0]', { item1: 'aaaaa', desc1: 'bbbbb' });    //PATH & UPDATE is defined in ACTIONS (reducer just implements)
      console.log(cloneItemUpdate);
      //Remerge
      _.merge(clone, cloneItemUpdate);
      console.log(clone);
      
      return clone;
    }

    case 'TEST_UPDATE_2':
    {
      //GOAL - Update 2 different fields in the same update, but leave everything else.
      //STATUS - WORKS
      //REQUIRES - Provide an updateRecords array with the changes required.
      const clone = Object.assign({}, state);
      console.log('CORE - TEST_UPDATE_2');
      console.log(clone);
      
      let updateRecords = [
        {
          dotPath: 'test5.nested1.arr3[0]',
          updateValue: { item1: 'aaaaa', desc1: 'bbbbb' }
        },
        {
          dotPath: 'test5.nested2.arr3[1]',
          updateValue: { item1: 'zzzzz', desc1: 'yyyyy' }
        },
      ];

      for(let z=0; z<updateRecords.length; z++) {
        console.log("For each record to update...");
        let dotPath = updateRecords[z].dotPath;
        let updateValue = updateRecords[z].updateValue;

        let cloneItemUpdate = Object.assign({}, clone);
        _.set(cloneItemUpdate, dotPath, updateValue);         //Update
        _.merge(clone, cloneItemUpdate);                      //Merge
        console.log("==== LOOP: CLONE VALUE ====");
        console.log(clone); 
        cloneItemUpdate=null;                                 //Clear
      }
      console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      console.log(clone); 
      return clone;
    }

    case 'TEST_UPDATE_2a':
    {
      //GOAL - TEST DATA FROM ACTION

      const clone = Object.assign({}, state);
      console.log('CORE - TEST_UPDATE_2a');
      console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z<updateRecords.length; z++) {
        console.log("For each record to update...");
        let dotPath = updateRecords[z].dotPath;
        let updateValue = updateRecords[z].updateValue;

        let cloneItemUpdate = Object.assign({}, clone);
        _.set(cloneItemUpdate, dotPath, updateValue);         //Update
        _.merge(clone, cloneItemUpdate);                      //Merge
        console.log("==== LOOP: CLONE VALUE ====");
        console.log(clone); 
        cloneItemUpdate=null;                                 //Clear
      }
      console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      console.log(clone); 
      return clone;
    }




    case 'TEST_UPDATE_3':
    {
      //GOAL - TEST DATA FROM ACTION (dynamic data)

      const clone = Object.assign({}, state);
      console.log('CORE - TEST_UPDATE_2a');
      console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z<updateRecords.length; z++) {
        console.log("For each record to update...");
        if (updateRecords!==null && updateRecords!==undefined) {
          let dotPath = updateRecords[z].dotPath;
          let updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          _.merge(clone, cloneItemUpdate);                      //Merge
          console.log("==== LOOP: CLONE VALUE ====");
          console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      console.log(clone); 
      return clone;
    }


    default:
      return state;
  }
};

export default CoreReducer;
